/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';

import LayoutMain from 'layouts/layout-main';
import VideoIntercom from 'components/shared/video-intercom';
import ImprovedSecurity from 'components/shared/improved-security';

import Intro from 'components/pages/health-check/intro';
import Quote from 'components/pages/health-check/quote';
import Temperature from 'components/pages/health-check/temperature';
import Dashboard from 'components/pages/health-check/dashboard';
import TouchlessAccess from 'components/pages/health-check/touchless-access';
import Features from 'components/pages/health-check/features';
import HubspotFormWrapper from 'components/lazy-blocks/hubspot-form-wrapper';

const HealthCheck = ({ data: { wpPage: data }, pageContext, location }) => (
  <LayoutMain
    seo={{ ...data.seo, date: pageContext.date }}
    headerWithGradient={false}
    withBanner={pageContext.showBanner}
    pageContext={pageContext}
    location={location}
  >
    <Intro
      title={data.acf.headline}
      description={data.acf.headlineSubtitle}
      buttonText={data.acf.introButtonText}
    />
    <Quote text={data.acf.section1Text} caption={data.acf.section1Caption} />
    <Temperature title={data.acf.section2Title} description={data.acf.section2Description} />
    <Dashboard
      title={data.acf.section3Title}
      description={data.acf.section3Description}
      panels={data.acf.section3Panels}
    />
    <TouchlessAccess title={data.acf.feature1Title} description={data.acf.feature1Description} />
    <VideoIntercom
      title={data.acf.feature2Title}
      description={data.acf.feature2Description}
      image={data.acf.feature2Image.localFile.childImageSharp}
      theme="secondary"
    />
    <Features items={data.acf.section4Features} />
    <HubspotFormWrapper title={data.acf.section5Title} description={data.acf.section5Subtitle} />
    <ImprovedSecurity />
  </LayoutMain>
);

export default HealthCheck;

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      acf {
        headline
        headlineSubtitle
        introButtonText
        section1Text
        section1Caption
        section2Title
        section2Description
        section3Title
        section3Description
        section3Panels {
          title
          description
        }
        feature1Title
        feature1Description
        feature2Title
        feature2Description
        feature2Image {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 480)
            }
          }
        }
        section4Features {
          title
          description
          iconName
        }
        section5Title
        section5Subtitle
        section5ButtonText
        displayFollowUpPage
        followUpRedirectUrl
      }
      ...wpPageSeo
    }
  }
`;
