import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import useLottie from 'hooks/use-lottie';
import { useInView } from 'react-intersection-observer';

import Button from 'components/shared/button';
import Heading from 'components/shared/heading';

import styles from './intro.module.scss';
import HealthIcon from './svg/health-icon.inline.svg';
import animationData from './data/animation.json';

const cx = classNames.bind(styles);

const Intro = ({ title, description, buttonText }) => {
  const [animationVisibilityRef, inView] = useInView({
    triggerOnce: true,
  });

  const [isAnimationReady, setIsAnimationReady] = useState(false);
  const additionalClassNameAnimation = isAnimationReady ? 'visible' : 'hidden';

  const [animation, animationRef] = useLottie(
    {
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData,
    },
    {
      loaded_images() {
        setIsAnimationReady(true);
      },
    }
  );

  useEffect(() => {
    if (animation && isAnimationReady && inView) {
      animation.play();
    }
  }, [animation, isAnimationReady, inView]);

  return (
    <section className={cx('wrapper')} ref={animationVisibilityRef}>
      <div className="container">
        <div className={cx('content')}>
          <HealthIcon className={cx('icon')} />
          <Heading className={cx('title')} size="xl">
            {title}
          </Heading>
          <p className={cx('description')}>{description}</p>
          <Button className={cx('button')} to="#form" theme="accent-secondary">
            {buttonText}
          </Button>
        </div>

        <div className={cx('animation', additionalClassNameAnimation)} ref={animationRef} />
      </div>
    </section>
  );
};

Intro.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
};

export default Intro;
