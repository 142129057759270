import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './panel.module.scss';
import shape1 from './svg/shape-red.svg';
import shape2 from './svg/shape-yellow.svg';
import shape3 from './svg/shape-green.svg';
import shape4 from './svg/shape-blue-light.svg';
import shape5 from './svg/shape-blue.svg';
import shape6 from './svg/shape-purple.svg';

const cx = classNames.bind(styles);

const shapes = [shape1, shape2, shape3, shape4, shape5, shape6];

const Panel = ({ title, description, shapeIndex }) => (
  <div className={cx('wrapper')}>
    <h4 className={cx('title')}>{title}</h4>
    <p className={cx('description')} dangerouslySetInnerHTML={{ __html: description }} />
    <img className={cx('shape')} src={shapes[shapeIndex]} alt="" />
  </div>
);

Panel.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  shapeIndex: PropTypes.number.isRequired,
};

export default Panel;
