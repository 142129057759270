import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import classNames from 'classnames/bind';
import ProductContent from 'components/shared/product-content';

import styles from './touchless-access.module.scss';
import shape from './svg/shape.svg';
import NameIcon from './svg/name.inline.svg';
import MicrophoneIcon from './svg/microphone.inline.svg';
import PhoneIcon from './svg/phone.inline.svg';
import VideoIcon from './svg/video.inline.svg';
import ButtonIcon from './svg/button.inline.svg';

const cx = classNames.bind(styles);

const TouchlessAccess = (props) => {
  const { phone } = useStaticQuery(graphql`
    query {
      phone: file(relativePath: { eq: "health-check/touchless-access/smartphone.png" }) {
        childImageSharp {
          gatsbyImageData(width: 650)
        }
      }
    }
  `);

  return (
    <section className={cx('wrapper')}>
      <div className="container">
        <div className="columns">
          <div className="column is-12-mobile is-6-tablet">
            <ProductContent iconName="access" theme="secondary" {...props} />
          </div>
        </div>

        <div className={cx('illustration')} aria-hidden>
          <img className={cx('shape')} src={shape} alt="" />

          <NameIcon className={cx('name')} />
          <VideoIcon className={cx('video')} />
          <PhoneIcon className={cx('phone')} />
          <MicrophoneIcon className={cx('microphone')} />
          <ButtonIcon className={cx('button')} />

          <GatsbyImage className={cx('smartphone')} image={getImage(phone)} alt="" />
        </div>
      </div>
    </section>
  );
};

TouchlessAccess.propTypes = {
  title: PropTypes.node.isRequired,
  description: PropTypes.string.isRequired,
};

export default TouchlessAccess;
