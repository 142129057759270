/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { motion, AnimatePresence } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames/bind';

import Heading from 'components/shared/heading';
import useAutoChangeableIndex from 'hooks/use-auto-changeable-index';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ProgressCircle from './progress-circle';

import styles from './features.module.scss';

import shape1 from './svg/shape-1.svg';
import shape2 from './svg/shape-2.svg';
import dotPattern from './svg/dot-pattern.svg';

import CapacityIcon from './svg/capacity.inline.svg';
import CommunicationIcon from './svg/communication.inline.svg';
import ManagementIcon from './svg/management.inline.svg';
import ScheduleIcon from './svg/schedule.inline.svg';

const cx = classNames.bind(styles);

const ITEM_CHANGE_INTERVAL = 5000; // milliseconds

const featureCardAnimationVariants = {
  initial: {
    opacity: 0,
  },
  active: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

const Features = ({ items }) => {
  const [animationStartRef, isAnimationStarted] = useInView();

  const [
    activeItemIndex,
    startAnimation,
    restartAnimation,
    resetAnimation,
  ] = useAutoChangeableIndex(items.length, {
    interval: ITEM_CHANGE_INTERVAL,
  });

  useEffect(() => {
    if (!isAnimationStarted) resetAnimation();
    if (isAnimationStarted) startAnimation();
  }, [startAnimation, isAnimationStarted]);

  const { capacity, management, schedule, communication } = useStaticQuery(graphql`
    {
      capacity: file(relativePath: { eq: "health-check/features/capacity.png" }) {
        childImageSharp {
          gatsbyImageData(width: 520, breakpoints: [260, 520, 780, 1040])
        }
      }
      schedule: file(relativePath: { eq: "health-check/features/schedule.png" }) {
        childImageSharp {
          gatsbyImageData(width: 520, breakpoints: [260, 520, 780, 1040])
        }
      }
      management: file(relativePath: { eq: "health-check/features/management.png" }) {
        childImageSharp {
          gatsbyImageData(width: 520, breakpoints: [260, 520, 780, 1040])
        }
      }
      communication: file(relativePath: { eq: "health-check/features/communication.png" }) {
        childImageSharp {
          gatsbyImageData(width: 520, breakpoints: [260, 520, 780, 1040])
        }
      }
    }
  `);

  const collection = {
    capacity: {
      icon: CapacityIcon,
      image: capacity,
    },
    schedule: {
      icon: ScheduleIcon,
      image: schedule,
    },
    management: {
      icon: ManagementIcon,
      image: management,
    },
    communication: {
      icon: CommunicationIcon,
      image: communication,
    },
  };

  const dotPatternSide = activeItemIndex === 1 || activeItemIndex === 3 ? 'left' : 'right';

  return (
    <section className={cx('wrapper')}>
      <div className="container" ref={animationStartRef}>
        <div className="columns">
          <motion.div
            className={cx('column is-12-mobile is-3-tablet is-5-desktop is-4-widescreen', 'left')}
          >
            {items.map((tab, tabIndex) => {
              const Icon = collection[tab.iconName].icon;
              const isActive = isAnimationStarted && tabIndex === activeItemIndex;

              return (
                <div
                  className={cx('tab', { 'tab-active': isActive })}
                  role="button"
                  tabIndex="0"
                  onClick={() => restartAnimation(tabIndex)}
                  onKeyDown={() => restartAnimation(tabIndex)}
                  key={tabIndex}
                >
                  <Heading className={cx('tab-title')} tag="h3" size="md" color="tertiary">
                    {tab.title}
                  </Heading>
                  <ProgressCircle className={cx('tab-progress-circle')} animate={isActive}>
                    <Icon className={cx('tab-icon')} />
                  </ProgressCircle>
                </div>
              );
            })}
          </motion.div>

          <div
            className={cx(
              'column is-12-mobile is-10-tablet is-6-desktop is-6-widescreen is-offset-0-mobile is-offset-1-desktop is-offset-2-widescreen',
              'right'
            )}
          >
            <AnimatePresence exitBeforeEnter>
              {items.map(({ title, description, iconName }, index) =>
                index === activeItemIndex ? (
                  <motion.div
                    variants={featureCardAnimationVariants}
                    animate="active"
                    initial="initial"
                    exit="exit"
                    key={index}
                  >
                    <Heading className={cx('title')} tag="h2" size="xl" color="tertiary">
                      {title}
                    </Heading>
                    <p
                      className={cx('description')}
                      dangerouslySetInnerHTML={{ __html: description }}
                    />
                    <GatsbyImage
                      className={cx('illustration')}
                      image={getImage(collection[iconName].image)}
                      alt=""
                    />
                  </motion.div>
                ) : null
              )}
            </AnimatePresence>

            <img className={cx(`dot-pattern-${dotPatternSide}`)} src={dotPattern} alt="" />
          </div>
        </div>

        <img className={cx('shape-1')} src={shape1} alt="" />
        <img className={cx('shape-2')} src={shape2} alt="" />
      </div>
    </section>
  );
};

Features.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      iconName: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Features;
