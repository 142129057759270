import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import Heading from 'components/shared/heading';

import styles from './temperature.module.scss';

import Icon from './svg/temperature.inline.svg';
import tooltip from './svg/tooltip.svg';
import card from './svg/card.svg';
import shape from './svg/shape.svg';

const cx = classNames.bind(styles);

const Temperature = ({ title, description }) => {
  const { illustration } = useStaticQuery(graphql`
    query {
      illustration: file(relativePath: { eq: "health-check/temperature/illustration.png" }) {
        childImageSharp {
          gatsbyImageData(width: 640, breakpoints: [320, 640, 960, 1280])
        }
      }
    }
  `);
  return (
    <section className={cx('wrapper')}>
      <div className="container">
        <div className="columns">
          <div className="column is-12-mobile is-6-tablet">
            <Icon className={cx('icon')} />
            <Heading
              className={cx('title')}
              tag="h2"
              size="xl"
              highlightedWordsColor="secondary"
              innerHTML={title}
            />
            <p className={cx('description')} dangerouslySetInnerHTML={{ __html: description }} />
          </div>
        </div>
        <div className={cx('illustration-wrapper')} aria-hidden>
          <img className={cx('shape')} src={shape} alt="" loading="lazy" />
          <GatsbyImage className={cx('illustration')} image={getImage(illustration)} alt="" />
          <img className={cx('card')} src={card} alt="" loading="lazy" />
          <img className={cx('tooltip-1')} src={tooltip} alt="" loading="lazy" />
          <img className={cx('tooltip-2')} src={tooltip} alt="" loading="lazy" />
          <img className={cx('tooltip-3')} src={tooltip} alt="" loading="lazy" />
        </div>
      </div>
    </section>
  );
};

Temperature.propTypes = {
  title: PropTypes.node.isRequired,
  description: PropTypes.string.isRequired,
};

export default Temperature;
