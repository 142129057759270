import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './quote.module.scss';
import QuoteIcon from './svg/quote-icon.inline.svg';

const cx = classNames.bind(styles);

const Quote = ({ text, caption }) => (
  <div className={cx('wrapper')}>
    <QuoteIcon className={cx('icon')} />
    <figure>
      <blockquote>
        <p dangerouslySetInnerHTML={{ __html: text }} />
      </blockquote>
      <figcaption>{caption}</figcaption>
    </figure>
  </div>
);

Quote.propTypes = {
  text: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
};

export default Quote;
