import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';
import classNames from 'classnames/bind';

import Heading from 'components/shared/heading';
import Panel from './panel';

import styles from './dashboard.module.scss';
import Icon from './svg/dashboard.inline.svg';
import shape1 from './svg/shape-1.svg';
import shape2 from './svg/shape-2.svg';

const cx = classNames.bind(styles);

const Dashboard = ({ title, description, panels }) => {
  const { dashboard } = useStaticQuery(graphql`
    query {
      dashboard: file(relativePath: { eq: "health-check/dashboard/dashboard.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 850)
        }
      }
    }
  `);

  return (
    <section className={cx('wrapper')}>
      <div className="container">
        <div className={cx('inner')}>
          <Icon className={cx('icon')} />
          <Heading className={cx('title')} tag="h2" size="xl" color="tertiary">
            {title}
          </Heading>
          <p className={cx('description')}>{description}</p>
        </div>

        <div className={cx('illustration-wrapper')}>
          <GatsbyImage className={cx('illustration')} image={getImage(dashboard)} alt="" />

          <div className={cx('panels-wrapper')}>
            {panels.map((panel, index) => (
              <Panel
                title={panel.title}
                description={panel.description}
                shapeIndex={index}
                key={index}
              />
            ))}
          </div>
        </div>

        <img className={cx('shape-1')} src={shape1} alt="" loading="lazy" aria-hidden />
        <img className={cx('shape-2')} src={shape2} alt="" loading="lazy" aria-hidden />
      </div>
    </section>
  );
};

Dashboard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  panels: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Dashboard;
