import React from 'react';
import PropTypes from 'prop-types';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import classNames from 'classnames/bind';

import ProductContent from 'components/shared/product-content';

import shape from './images/shape.svg';

import styles from './video-intercom.module.scss';

const cx = classNames.bind(styles);

const VideoIntercom = ({ image, ...props }) => (
  <section className={cx('wrapper')}>
    <div className="container">
      <div className={cx('inner')}>
        <div className={cx('content')}>
          <ProductContent iconName="videoIntercom" {...props} />
        </div>
        <div className={cx('illustration')} aria-hidden>
          <img className={cx('shape')} src={shape} loading="lazy" alt="" />
          <GatsbyImage className={cx('illustration-item')} image={getImage(image)} alt="" />
        </div>
      </div>
    </div>
  </section>
);

VideoIntercom.propTypes = {
  title: PropTypes.node.isRequired,
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  buttonUrl: PropTypes.string,
  image: PropTypes.objectOf(PropTypes.any).isRequired,
};

VideoIntercom.defaultProps = {
  buttonText: null,
  buttonUrl: null,
};

export default VideoIntercom;
